$(document).on('turbolinks:load', function(){
  // ブラウザのデフォルトの遷移確認を無効化
  window.onbeforeunload = null;

  const getAllowedUrls = (selectors) => {
    return Array.from(document.querySelectorAll(selectors))
      .map((button) => button.href)
      .filter(Boolean);
  };
  const beforeVisitHandler = (e, allowedUrls, message, cleanupFunction, flagFunction = (e) => true) => {
    const targetUrl = e?.data?.url;
    if (allowedUrls.includes(targetUrl) && flagFunction(e)) {
      cleanupFunction(e);
      return;
    }
    if (!confirm(message)) {
      e.stopImmediatePropagation();
      e.preventDefault();
      hideLoading();
    } else {
      cleanupFunction(e);
    }
  };
  if ($('body').hasClass("listings new") || $('body').hasClass("listings edit")) {
    if ($('#listing_year_unknown').prop('checked')) {
      $('#listing_model_year').val("");
      $('#listing_model_year').prop("disabled", true).addClass("disabled");
    } else {
      $('#listing_model_year').prop("disabled", false).removeClass("disabled");
    }
    $('#listing_year_unknown').change(function () {
      if ($('#listing_year_unknown').prop('checked')) {
        $('#listing_model_year').val("");
        $('#listing_model_year').prop("disabled", true).addClass("disabled");
      } else {
        $('#listing_model_year').prop("disabled", false).removeClass("disabled");
      }
    });
    if ($('#listing_contact_flg').prop('checked')) {
      $('#CalcPriceFrom').css('display', 'none');
      $('#listing_price').val("");
      $('#listing_price_commission').text("");
      $('#listing_price_profit').text("");
      $('#listing_price_price_on_tax').text("");
      $('#listing_price_price_in_tax').text("");
      $('#listing_price_commission_on_tax').text("");
      $('#listing_price_profit_on_tax').text("");
      $('#listing_price').prop("disabled", true).addClass("disabled");
    } else {
      $('#CalcPriceFrom').css('display', 'block');
      $('#listing_price').prop("disabled", false).removeClass("disabled");
    }
    $('#listing_contact_flg').change(function () {
      if ($('#listing_contact_flg').prop('checked')) {
        $('#CalcPriceFrom').css('display', 'none');
        $('#listing_price').val("");
        $('#listing_price_commission').text("");
        $('#listing_price_profit').text("");
        $('#listing_price_price_on_tax').text("");
        $('#listing_price_commission_on_tax').text("");
        $('#listing_price_commission_on_tax').text("");
        $('#listing_price_profit_on_tax').text("");
        $('#listing_price').prop("disabled", true).addClass("disabled");
      } else {
        $('#CalcPriceFrom').css('display', 'block');
        $('#listing_price').prop("disabled", false).removeClass("disabled");
      }
    });
    $(function () {
      $('.count_up1').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up1-text').text(len)
      })
      $('.count_up2').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up2-text').text(len)
      })
      $('.count_up3').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up3-text').text(len)
      })
      $('.count_up4').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up4-text').text(len)
      })
      $('.count_up5').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up5-text').text(len)
      })
      $('.count_up6').on('keyup', function () {
        var len = textLength($(this).val())
        $('.count_up6-text').text(len)
      })

    })
    $(function () {
      //1
      var elements1 = document.getElementsByClassName('count_up1')
      const value1 = elements1[0].value
      var elements1_text = document.getElementsByClassName('count_up1-text')
      elements1_text[0].innerHTML = value1.length
      //2
      var elements2 = document.getElementsByClassName('count_up2')
      const value2 = elements2[0].value
      var elements2_text = document.getElementsByClassName('count_up2-text')
      elements2_text[0].innerHTML = value2.length
      //3
      // var elements3 = document.getElementsByClassName('count_up3')
      // const value3 = elements3[0].value
      // var elements3_text = document.getElementsByClassName('count_up3-text')
      // elements3_text[0].innerHTML = value3.length
      //4
      var elements4 = document.getElementsByClassName('count_up4')
      const value4 = elements4[0].value
      var elements4_text = document.getElementsByClassName('count_up4-text')
      elements4_text[0].innerHTML = value4.length
      //5
      var elements5 = document.getElementsByClassName('count_up5')
      const value5 = elements5[0].value
      var elements5_text = document.getElementsByClassName('count_up5-text')
      elements5_text[0].innerHTML = value5.length
    })
    function textLength(text) {
      var regexp = /[\x01-\x7E\u{FF65}-\u{FF9F}]/mu
      var len = 0
      for (i = 0; i < text.length; i++) {
        var ch = text[i]
        len += regexp.test(new String(ch)) ? 1 : 1
      }
      return len
    }

    var form = document.getElementById('listing_form');
    const allowedUrls = [form.action];
    const cleanupListeners = function(e) {
      document.removeEventListener('turbolinks:before-visit', handleBeforeVisit);
      document.removeEventListener('turbolinks:before-cache', cleanupListeners);
    };
    const handleBeforeVisit = (e) => beforeVisitHandler(e, allowedUrls, '他のページに移動しますか？行った変更が保存されない可能性があります。', cleanupListeners);
    document.addEventListener('turbolinks:before-visit', handleBeforeVisit);
    document.addEventListener('turbolinks:before-cache', cleanupListeners);
  }

  if ($('body').hasClass("listings file_upload")) {
    const allowedUrls = getAllowedUrls('a.next-button, a.return-button');
    const cleanupListeners = function(e) {
      document.removeEventListener('turbolinks:before-visit', handleBeforeVisit);
      document.removeEventListener('turbolinks:before-cache', cleanupListeners);
    };
    const notEmptyImageReturn = function(e) {
      const returnUrls = getAllowedUrls('a.return-button');
      const targetUrl = e?.data?.url;
      if (!returnUrls.includes(targetUrl)) {
        return true;
      }
      const firstComponent = document.querySelector('[data-react-class="ImageUpload"]');
      if (firstComponent) {
        const subContainers = firstComponent.querySelectorAll('.sub-container');
        const hasImage = Array.from(subContainers).some((subContainer) => {
          const imageItems = subContainer.querySelectorAll('.image-item');
          return Array.from(imageItems).some((imageItem) => {
            const imgElement = imageItem.querySelector('img');
            return imgElement && imgElement.src; // 画像があればtrue
          });
        });
        return hasImage;
      }
      return false;
    }
    const handleBeforeVisit = (e) => beforeVisitHandler(e, allowedUrls, '他のページに移動しますか？行った変更が保存されない可能性があります。', cleanupListeners, notEmptyImageReturn);
    document.addEventListener('turbolinks:before-visit', handleBeforeVisit);
    document.addEventListener('turbolinks:before-cache', cleanupListeners);
  }
  if ($('body').hasClass("listings preview")) {
    $('.listing-button').addClass("disabled");
    $("[name='check_agree']").click(function() {
      var result = $("[name='check_agree']:checked").val();
      if(result){
        $('.listing-button').removeClass("disabled");
      } else {
        $('.listing-button').addClass("disabled");
      }
    });
    const allowedUrls = getAllowedUrls('a.listing-button, a.return-button');
    const cleanupListeners = function(e) {
      document.removeEventListener('turbolinks:before-visit', handleBeforeVisit);
      document.removeEventListener('turbolinks:before-cache', cleanupListeners);
    };
    const handleBeforeVisit = (e) => beforeVisitHandler(e, allowedUrls, '他のページに移動しますか？行った変更が保存されない可能性があります。', cleanupListeners);
    document.addEventListener('turbolinks:before-visit', handleBeforeVisit);
    document.addEventListener('turbolinks:before-cache', cleanupListeners);
  }
});