import React from 'react'
import Select, { components } from 'react-select'

interface CategoryOption {
  label: string;
  value: number;
}

interface IProps {
  signed_in: boolean;
  mainCategoryList: CategoryOption[];
  subCategoryList: CategoryOption[];
  selectedDefalutMainCategory: CategoryOption;
  selectedDefalutSubCategory: CategoryOption;
}

const CustomLoadingIndicator = () => (
  <div style={{ padding: '2px 8px' }}>
    <div style={{ display: 'flex', gap: '2px' }}>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
      }}></div>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
        animationDelay: '0.1s',
      }}></div>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
        animationDelay: '0.2s',
      }}></div>
    </div>
    <style>
      {`
        @keyframes dot {
          0%, 80%, 100% { transform: scale(0); }
          40% { transform: scale(1.0); }
        }
      `}
    </style>
  </div>
);

const SelectCategory: React.FC<IProps> = props => {
  const [isInitialLoading, setIsInitialLoading] = React.useState<boolean>(true);
  const [isSubLoading, setIsSubLoading] = React.useState<boolean>(false);
  const [mainCategoryList, setMainCategoryList] = React.useState<CategoryOption[]>([]);
  const [subCategoryList, setSubCategoryList] = React.useState<CategoryOption[]>(props.subCategoryList || []);
  const [selectedMainCategory, setSelectedMainCategory] = React.useState<CategoryOption>(props.selectedDefalutMainCategory);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState<CategoryOption>(props.selectedDefalutSubCategory);

  const updateCategoryDisplayPattern = (categoryId: number) => {
    const patterns = {
      show: ['1', '2', '4', '5', '7'].includes(String(categoryId)),
      showPacking: categoryId === 6,
    };

    const elements = {
      pattern1: document.getElementsByClassName('category-display-pattern-1'),
      pattern2: document.getElementsByClassName('category-display-pattern-2'),
      block: document.getElementsByClassName('category-display-pattern-block'),
    };

    Array.from(elements.pattern1).forEach((el) => {
      (el as HTMLElement).style.display = patterns.show ? 'block' : 'none';
    });

    Array.from(elements.pattern2).forEach((el) => {
      (el as HTMLElement).style.display = patterns.showPacking ? 'block' : 'none';
    });

    Array.from(elements.block).forEach((el) => {
      (el as HTMLElement).style.display = (patterns.show || patterns.showPacking) ? 'block' : 'none';
    });
  };

  const fetchSubCategories = async (parentId: number) => {
    if (parentId === 999) {
      setSubCategoryList([]);
      return;
    }

    try {
      setIsSubLoading(true);
      const response = await fetch(`/categories?parent_id=${parentId}`);
      if (!response.ok) throw new Error('Failed to fetch sub categories');
      const json = await response.json();
      setSubCategoryList(json.data);
    } catch (error) {
      console.error('Error fetching sub categories:', error);
      setSubCategoryList([]);
    } finally {
      setIsSubLoading(false);
    }
  };

  React.useEffect(() => {
    const initializeCategories = async () => {
      try {
        setIsInitialLoading(true);
        // メインカテゴリーの初期化
        setMainCategoryList(props.mainCategoryList);

        // 初期サブカテゴリーの取得
        if (selectedMainCategory?.value && !props.subCategoryList?.length) {
          await fetchSubCategories(selectedMainCategory.value);
        }

        if (selectedMainCategory?.value) {
          updateCategoryDisplayPattern(selectedMainCategory.value);
        }
      } catch (error) {
        console.error('Error initializing categories:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    initializeCategories();
  }, []);

  React.useEffect(() => {
    if (selectedMainCategory?.value) {
      updateCategoryDisplayPattern(selectedMainCategory.value);
    }
  }, [selectedMainCategory]);

  const handleMainCategoryChange = (option: CategoryOption | null) => {
    if (!option) return;
    setSelectedMainCategory(option);
    setSelectedSubCategory({ label: "サブカテゴリー", value: 0 });
    fetchSubCategories(option.value);
  };

  if (isInitialLoading) {
    return (
      <div className="category-wrap select-double">
        <Select<CategoryOption>
          options={[]}
          value={null}
          placeholder="読み込み中..."
          isLoading={true}
          className="select-w mr-4"
          loadingMessage={() => "読み込み中..."}
          components={{ LoadingIndicator: CustomLoadingIndicator }}
        />
        <Select<CategoryOption>
          options={[]}
          value={null}
          placeholder="読み込み中..."
          isLoading={true}
          className="select-w"
          loadingMessage={() => "読み込み中..."}
          components={{ LoadingIndicator: CustomLoadingIndicator }}
        />
      </div>
    );
  }

  return (
    <div className="category-wrap select-double">
      <Select<CategoryOption>
        options={mainCategoryList}
        value={selectedMainCategory}
        onChange={handleMainCategoryChange}
        placeholder="メインカテゴリー"
        name="listing[main_category_id]"
        className="select-w mr-4"
        components={{ LoadingIndicator: CustomLoadingIndicator }}
      />
      <Select<CategoryOption>
        options={subCategoryList}
        value={selectedSubCategory}
        onChange={(option) => option && setSelectedSubCategory(option)}
        placeholder="サブカテゴリー"
        name="listing[sub_category_id]"
        className="select-w"
        isLoading={isSubLoading}
        loadingMessage={() => "読み込み中..."}
        components={{ LoadingIndicator: CustomLoadingIndicator }}
      />
    </div>
  );
};

export default SelectCategory;
