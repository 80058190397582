import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components } from 'react-select';

interface IProps {
  signd_in: boolean,
  listing_makers: any[],
  selectedMaker: string,
}

const CustomLoadingIndicator = () => (
  <div style={{ padding: '2px 8px' }}>
    <div style={{ display: 'flex', gap: '2px' }}>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
      }}></div>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
        animationDelay: '0.1s',
      }}></div>
      <div style={{
        width: '4px',
        height: '4px',
        background: '#ccc',
        borderRadius: '50%',
        animation: 'dot 0.8s infinite',
        animationDelay: '0.2s',
      }}></div>
    </div>
    <style>
      {`
        @keyframes dot {
          0%, 80%, 100% { transform: scale(0); }
          40% { transform: scale(1.0); }
        }
      `}
    </style>
  </div>
);

const SelectMaker: React.FC<IProps> = props => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [maker, setMaker] = React.useState(() => {
    if (props.selectedMaker) {
      return { label: props.selectedMaker, value: props.selectedMaker }
    } else {
      return { label: "", value: "" }
    }
  })
  const [makerList, setMakerList] = React.useState<any[]>(props.listing_makers)

  const onInputChange = async (inputValue: any) => {
    if (inputValue.length > 50) { return true }
    setMaker({ label: inputValue, value: inputValue })
    if (inputValue.length == 0) {
      return
    }
    setIsLoading(true)
    await fetch(`/listing_makers?keyword=${inputValue}`, {
      method: "get"
    }).then((response) => {
      if (!response.ok) {
          throw new Error();
      }
      return response.json()
    }).then((json) => {
      if (json.data != null) {
        setMakerList(json.data)
      }
      setIsLoading(false)
    })
  }

  const formatCreate = (inputValue) => {
    return (<p>{inputValue}</p>);
  };

  return (
    <CreatableSelect
      isClearable
      name='listing[maker_name]'
      className='w−52'
      inputValue={maker.label}
      isLoading={isLoading}
      onInputChange={onInputChange}
      options={makerList}
      defaultValue={maker}
      formatCreateLabel={formatCreate}
      placeholder="メーカーを入力"
      loadingMessage={() => "読み込み中..."}
      components={{ LoadingIndicator: CustomLoadingIndicator }}
    />
  )
}

export default SelectMaker